var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component-large",
      attrs: { title: "日志", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "things-sync-preview-dialog form-edit-component-large transform-dialog",
        },
        [
          _c("span", { staticStyle: { "margin-right": "20px" } }, [
            _vm._v("同步产品时间：" + _vm._s(_vm.syncTime)),
          ]),
          _c(
            "div",
            { staticClass: "flex", staticStyle: { "margin-bottom": "10px" } },
            [
              _c("span", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("同步产品数：" + _vm._s(_vm.total)),
              ]),
            ]
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.listData,
              "row-key": "id",
              size: _vm.TableSize,
              pagination: _vm.Pagination,
            },
            on: { change: _vm.tableChange },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }