




























































































import { Component, Mixins } from 'vue-property-decorator';
import GroupComponent from '@common-src/mixins/group-component';
import TableTreeComponent from '@common-src/mixins/table-tree-component';
import DeviceTypeDetailDrawer from './device-type-detail-drawer.vue';
import ImportJsonDialog from './import-json-dialog.vue';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { DeviceTypeEntityModel, DeviceTypeQueryModel, ImportJsonEntityModel } from '@common-src/entity-model/device-type-entity';
import DeviceTypeService from '@common-src/service3/device-type';
import DeviceTypeService2 from '@common-src/service/device-type';
import { DeviceTypeEnum, ViewModeType, SyncType, ThingsEntityType } from '@common-src/model/enum';
import ErrorConfigDialog from './error-config-dialog.vue';
import { ModuleType } from '@common-src/enum/module-type.enum';
import UpdateGroupComponent from './update-group.vue';
import { UserStoreModule } from '@common-src/store/modules/user';
import ThingsSyncDialog from './things-sync-dialog.vue';
import DriveEditDialog from '../device-driver/driver-edit.dialog.vue';
import EdageAsyncCheckDialog from './edage-sync-check.dialog.vue';
import DeviceTypeBatchGroupDialog from './device-type-batch-group.vue';

@Component({
    components: {
        'device-type-detail-drawer': DeviceTypeDetailDrawer,
        'import-json-dialog': ImportJsonDialog,
        'error-config-dialog': ErrorConfigDialog,
        'things-sync-dialog': ThingsSyncDialog,
        'drive-edit-dialog': DriveEditDialog,
        'edage-async-check-dialog': EdageAsyncCheckDialog,
        'device-type-batch-group-dialog': DeviceTypeBatchGroupDialog,
        UpdateGroupComponent
    }
})
export default class DeviceTypeListComponent extends Mixins(TableDialogFormComponent, GroupComponent, TableTreeComponent) {
    DeviceTypeEntityModel = DeviceTypeEntityModel;
    DeviceTypeEnum = DeviceTypeEnum;
    syncNumber = 0;
    created() {
        const deviceTypeQueryModel = new DeviceTypeQueryModel();
        const IsEdge = this.IsEdge;
        if (IsEdge) {
            this.edgeSyncNum();
        }
        this.initTable({
            service: new DeviceTypeService(),
            queryModel: deviceTypeQueryModel,
            tableColumns: DeviceTypeEntityModel.getTableColumns(IsEdge)
        });
    }

    get IsEdge() {
        return JTL.moduleType === ModuleType.EDGE;
    }

    /**
     * 是否是超级管理员
     */
    get IsSuperAdmin(): boolean {
        return _.get(UserStoreModule.UserInfo, 'IsSuperAdmin');
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as DeviceTypeQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/device-type-list?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/device-type-list');
        }
    }

    detailClick(model: DeviceTypeEntityModel) {
        (this.$refs.deviceTypeDetailDrawer as DeviceTypeDetailDrawer).drawerShow(model);
    }
    manageClick(id: string) {
        this.$router.push({
            path: '/dashboard/device-list',
            query: { deviceTypeId: id }
        });
    }

    importJson() {
        const importModel = new ImportJsonEntityModel();
        (this.$refs.importJsonlDialog as ImportJsonDialog).dialogOpen(importModel, null, ViewModeType.NEW);
    }

    exportJson() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择产品');
            return;
        }
        this.startFullScreenLoading('正在导出产品');
        DeviceTypeService2.exportDeviceTypeJson(this.getSelectedRowIds()).then(res => {
            this.showMessageSuccess('导出产品成功');
        }).catch((err) => {
            throw err;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    errorConfigClick(deviceType: DeviceTypeEntityModel) {
        (this.$refs.errorConfigDialog as ErrorConfigDialog).dialogOpen(deviceType.id, deviceType.name);
    }

    updateGroupClick(deviceType: DeviceTypeEntityModel) {
        (this.$refs.updateGroupDialog as UpdateGroupComponent).dialogOpen(deviceType);
    }
    editDriveClick(deviceType: DeviceTypeEntityModel) {
        (this.$refs.driveEditDialog as DriveEditDialog).dialogOpen(deviceType);
    }
    //  同步操作
    syncClick() {
        switch (true) {
            case !!(!this.IsSuperAdmin && !this.IsEdge):
                return (this.$refs.thingsSyncDialog as ThingsSyncDialog).dialogOpen(SyncType.DOWNLOAD, ThingsEntityType.TEMPLATE);
            case !!this.IsEdge:
                return (this.$refs.edageAsyncCheckDialog as EdageAsyncCheckDialog).dialogOpen();
            default:
                break;
        }
    }
    // 获取边缘同步数量
    edgeSyncNum(mark?: boolean) {
        DeviceTypeService2.currentlySynchronizableEdgeNum().then((res) => {
            this.syncNumber = res || 0;
            if (mark) {
                this.$forceUpdate();
            }
        });
    }
    groupBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择产品');
            return;
        }
        (this.$refs.deviceTypeBatchGroupDialog as DeviceTypeBatchGroupDialog).dialogOpen(this.getSelectedRowIds(), null, ViewModeType.NEW);
    }
}
