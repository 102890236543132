





























import { Component, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TableComponent from '@common-src/mixins/table-component';
import { ThingsFunctionEntityModel } from '@common-src/entity-model/things-function-entity';
import DeviceTypeService2 from '@common-src/service/device-type';

@Component
export default class EdageSyncLogDialog extends TableComponent<any, any> {
    dialogVisible: boolean = false;
    action = 'action';
    syncTime: string = '';
    dialogOpen(type, model) {
        this.dialogVisible = true;
        this.$nextTick(async() => {
            this.resetpage();
            this.initTable({
                listFunc: DeviceTypeService2.statisticSyncDoneList,
                queryModel: { params: { syncId: model.id } },
                tableColumns: ThingsFunctionEntityModel.getEdageTypeTabelDetailColumns(type)
            });
            this.syncTime = moment(model?.syncTime).format('YYYY-MM-DD HH:mm:ss');
            if (this.isFirstLoad) {
                this.getList();
            }
        });
    }
    resetpage() {
        this.page = 1;
        this.pageSize = 20;
    }
    dialogClose(): void {
        this.dialogVisible = false;
    }
}
