var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component form-edit-component-large",
      attrs: { title: _vm.dialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "error-config-dialog" },
        [
          _c(
            "a-card",
            {
              attrs: { bordered: false },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", [_vm._v("故障状态")]),
                      _vm._v("  "),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.saveFaultStatus },
                        },
                        [_vm._v("保存")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "a-descriptions",
                { staticClass: "tab-margin-16", attrs: { bordered: "" } },
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "故障(1)", span: 3 } },
                    [
                      _c("div", [
                        _vm._v("设备属性满足以下条件，状态为“故障”："),
                      ]),
                      _vm._l(_vm.faultAttributeList, function (item, index) {
                        return [
                          _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "attribute-item-wrapper",
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: {
                                    placeholder: "请选择属性",
                                    "allow-clear": true,
                                    "show-search": "",
                                    "option-filter-prop": "title",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.attributeChange(item)
                                    },
                                  },
                                  model: {
                                    value: item.attributeKey,
                                    callback: function ($$v) {
                                      _vm.$set(item, "attributeKey", $$v)
                                    },
                                    expression: "item.attributeKey",
                                  },
                                },
                                _vm._l(_vm.allAttributes, function (option) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: option.identifier,
                                      attrs: {
                                        value: option.identifier,
                                        title:
                                          option.name +
                                          " | " +
                                          option.identifier,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(option.name) +
                                          " | " +
                                          _vm._s(option.identifier) +
                                          " "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v("  "),
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    placeholder: "请选择属性值",
                                    "allow-clear": true,
                                    "show-search": "",
                                    "option-filter-prop": "title",
                                  },
                                  model: {
                                    value: item.attributeValue,
                                    callback: function ($$v) {
                                      _vm.$set(item, "attributeValue", $$v)
                                    },
                                    expression: "item.attributeValue",
                                  },
                                },
                                _vm._l(item.optionList, function (option) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: option.value,
                                      attrs: {
                                        value: option.value,
                                        title: option.name,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(option.name || option.label) +
                                          " "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v("  "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(
                                        index,
                                        item,
                                        "faultAttribute"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                      _c("a", { on: { click: _vm.addAttribute } }, [
                        _vm._v("+添加属性"),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "正常(0)", span: 3 } },
                    [_vm._v(" 已配置故障状态，非以上情形时，状态为“正常” ")]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "未知(-1)", span: 3 } },
                    [_vm._v(" 未配置故障状态时，状态为“未知” ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-card",
            {
              attrs: { bordered: false },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", [_vm._v("运行状态")]),
                      _vm._v("  "),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.saveRunStatus },
                        },
                        [_vm._v("保存")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "a-descriptions",
                { staticClass: "tab-margin-16", attrs: { bordered: "" } },
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "运行(1)", span: 3 } },
                    [
                      _c("div", [
                        _vm._v("设备属性满足以下条件，状态为“运行”："),
                      ]),
                      _vm._l(_vm.runAttributeList, function (item, index) {
                        return [
                          _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "attribute-item-wrapper",
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: {
                                    placeholder: "请选择属性",
                                    "allow-clear": true,
                                    "show-search": "",
                                    "option-filter-prop": "title",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.attributeChange(item)
                                    },
                                  },
                                  model: {
                                    value: item.attributeKey,
                                    callback: function ($$v) {
                                      _vm.$set(item, "attributeKey", $$v)
                                    },
                                    expression: "item.attributeKey",
                                  },
                                },
                                _vm._l(_vm.allAttributes, function (option) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: option.identifier,
                                      attrs: {
                                        value: option.identifier,
                                        title:
                                          option.name +
                                          " | " +
                                          option.identifier,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(option.name) +
                                          " | " +
                                          _vm._s(option.identifier) +
                                          " "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v("  "),
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    placeholder: "请选择属性值",
                                    "allow-clear": true,
                                    "show-search": "",
                                    "option-filter-prop": "title",
                                  },
                                  model: {
                                    value: item.attributeValue,
                                    callback: function ($$v) {
                                      _vm.$set(item, "attributeValue", $$v)
                                    },
                                    expression: "item.attributeValue",
                                  },
                                },
                                _vm._l(item.optionList, function (option) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: option.value,
                                      attrs: {
                                        value: option.value,
                                        title: option.name,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(option.name || option.label) +
                                          " "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v("  "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(
                                        index,
                                        item,
                                        "runAttribute"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                      _c("a", { on: { click: _vm.addRunAttribute } }, [
                        _vm._v("+添加属性"),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "停机(0)", span: 3 } },
                    [_vm._v(" 已配置运行状态，非以上情形时，状态为“停机” ")]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "未知(-1)", span: 3 } },
                    [_vm._v(" 未配置运行状态时，状态为“未知” ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-card",
            {
              attrs: { bordered: false },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", [_vm._v("可控状态")]),
                      _vm._v("  "),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.saveControlStatus },
                        },
                        [_vm._v("保存")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "a-descriptions",
                { staticClass: "tab-margin-16", attrs: { bordered: "" } },
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "可控(true)", span: 3 } },
                    [
                      _c("div", [
                        _vm._v("设备属性满足以下条件，状态为“可控”："),
                      ]),
                      _vm._l(_vm.controlAttributeList, function (item, index) {
                        return [
                          _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "attribute-item-wrapper",
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: {
                                    placeholder: "请选择属性",
                                    "allow-clear": true,
                                    "show-search": "",
                                    "option-filter-prop": "title",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.attributeChange(item)
                                    },
                                  },
                                  model: {
                                    value: item.attributeKey,
                                    callback: function ($$v) {
                                      _vm.$set(item, "attributeKey", $$v)
                                    },
                                    expression: "item.attributeKey",
                                  },
                                },
                                _vm._l(_vm.allAttributes, function (option) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: option.identifier,
                                      attrs: {
                                        value: option.identifier,
                                        title:
                                          option.name +
                                          " | " +
                                          option.identifier,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(option.name) +
                                          " | " +
                                          _vm._s(option.identifier) +
                                          " "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v("  "),
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    placeholder: "请选择属性值",
                                    "allow-clear": true,
                                    "show-search": "",
                                    "option-filter-prop": "title",
                                  },
                                  model: {
                                    value: item.attributeValue,
                                    callback: function ($$v) {
                                      _vm.$set(item, "attributeValue", $$v)
                                    },
                                    expression: "item.attributeValue",
                                  },
                                },
                                _vm._l(item.optionList, function (option) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: option.value,
                                      attrs: {
                                        value: option.value,
                                        title: option.name,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(option.name || option.label) +
                                          " "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._v("  "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(
                                        index,
                                        item,
                                        "controlAttribute"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                      _c("a", { on: { click: _vm.addControlAttribute } }, [
                        _vm._v("+添加属性"),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "不可控(false)", span: 3 } },
                    [_vm._v(" 已配置可控状态，非以上情形时，状态为“不可控” ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.dialogOK.bind(this) },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }