

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import DeviceTypeService from '@common-src/service/device-type';
import { ImportJsonEntityModel } from '@common-src/entity-model/device-type-entity';

@Component
export default class ImportJsonComponent extends FormDialogComponent<ImportJsonEntityModel> {
    dialogOpen(importModel: ImportJsonEntityModel, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '导入';
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(importModel, viewMode);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导入，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                DeviceTypeService.importDeviceTypesByJsonFile(this.jtlForm.formModel).then(res => {
                    // if (res) {
                    //     const failMessage = (res.failMessage && res.failMessage.length > 0) ? `导出失败：${res.failMessage.join(',')}` : '';
                    //     this.showMessageSuccess(`导入成功${res.successCount}个，失败${res.failCount}个！${failMessage}`);
                    // } else {
                    //     this.showMessageSuccess('导入成功');
                    // }
                    this.showMessageSuccess('导入成功');
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
