
































































































































































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import DeviceTypeService from '@common-src/service3/device-type';
import { AttributeType, ThingsEntityType, ThingsModelType } from '@common-src/model/enum';
import { ThingsAttributeEntityModel } from '@common-src/entity-model/things-attribute-entity';
import ThingsAttributeService from '@common-src/service3/things-attribute';

const FAULT_PARAM = {
    name: '故障参数',
    identifier: 'NbFaultParam',
    separatorAttribute: ';',
    separatorValue: '='
};
const RUN_PARAM = {
    name: '运行参数',
    identifier: 'NbRunningParam',
    separatorAttribute: ';',
    separatorValue: '='
};
const CONTROL_PARAM = {
    name: '可控参数',
    identifier: 'NbControlParam',
    separatorAttribute: ';',
    separatorValue: '='
};
const deviceTypeService = new DeviceTypeService();
const thingsAttributeService = new ThingsAttributeService();

@Component
export default class ErrorConfigDialog extends BaseComponent {
    dialogVisible: boolean = false;
    dialogTitle: string = null;
    deviceTypeId: string = null;
    faultAttributeList: Array<any> = null;
    runAttributeList: Array<any> = null;
    controlAttributeList: Array<any> = null;
    allAttributes: Array<ThingsAttributeEntityModel> = null;
    faultParamAttribute: ThingsAttributeEntityModel;
    runParamAttribute: ThingsAttributeEntityModel;
    controlParamAttribute: ThingsAttributeEntityModel;

    dialogOpen(deviceTypeId: string, deviceTypeName: string): void {
        this.deviceTypeId = deviceTypeId;
        this.dialogTitle = `故障配置-${deviceTypeName}`;
        this.dialogVisible = true;
        deviceTypeService.retrieve(deviceTypeId).then(res => {
            // 只查询客户端属性，且是布尔和枚举类型
            this.allAttributes = _.filter(res.attrs, item => item.attributeType === AttributeType.CLIENT && item.thingsModelTypeModel && [ThingsModelType.BOOLEAN, ThingsModelType.ENUM].indexOf(item.thingsModelTypeModel.thingsModelType) > -1);
            this.faultParamAttribute = _.find(res.extendAttrs, item => item.identifier === FAULT_PARAM.identifier);
            if (this.faultParamAttribute) {
                this.faultAttributeList = _.map(this.faultParamAttribute.thingsModelTypeModel.value.split(FAULT_PARAM.separatorAttribute), item => {
                    const keyValueArray = item.split(FAULT_PARAM.separatorValue);
                    const attributeModel = {
                        attributeKey: _.get(keyValueArray, '[0]'),
                        attributeValue: _.get(keyValueArray, '[1]'),
                        optionList: null
                    };
                    this.attributeChange(attributeModel, false);
                    return attributeModel;
                });
            } else {
                this.faultAttributeList = [{ attributeKey: undefined, attributeValue: undefined, optionList: undefined }];
            }

            this.runParamAttribute = _.find(res.extendAttrs, item => item.identifier === RUN_PARAM.identifier);
            if (this.runParamAttribute) {
                this.runAttributeList = _.map(this.runParamAttribute.thingsModelTypeModel.value.split(RUN_PARAM.separatorAttribute), item => {
                    const keyValueArray = item.split(RUN_PARAM.separatorValue);
                    const attributeModel = {
                        attributeKey: _.get(keyValueArray, '[0]'),
                        attributeValue: _.get(keyValueArray, '[1]'),
                        optionList: null
                    };
                    this.attributeChange(attributeModel, false);
                    return attributeModel;
                });
            } else {
                this.runAttributeList = [{ attributeKey: undefined, attributeValue: undefined, optionList: undefined }];
            }

            this.controlParamAttribute = _.find(res.extendAttrs, item => item.identifier === CONTROL_PARAM.identifier);
            if (this.controlParamAttribute) {
                this.controlAttributeList = _.map(this.controlParamAttribute.thingsModelTypeModel.value.split(CONTROL_PARAM.separatorAttribute), item => {
                    const keyValueArray = item.split(CONTROL_PARAM.separatorValue);
                    const attributeModel = {
                        attributeKey: _.get(keyValueArray, '[0]'),
                        attributeValue: _.get(keyValueArray, '[1]'),
                        optionList: null
                    };
                    this.attributeChange(attributeModel, false);
                    return attributeModel;
                });
            } else {
                this.controlAttributeList = [{ attributeKey: undefined, attributeValue: undefined, optionList: undefined }];
            }
        });
    }

    saveRunStatus() {
        // if (this.runAttributeList) {
        //     for (let i = 0; i < this.runAttributeList.length; i++) {
        //         if (!this.runAttributeList[i].attributeKey || _.isNil(this.runAttributeList[i].attributeValue)) {
        //             this.showMessageWarning(`运行状态第${i + 1}行配置不能为空`);
        //             return;
        //         }
        //     }
        // }
        const runValue = _.map(this.handleEmpty(this.runAttributeList), item => `${item.attributeKey}${RUN_PARAM.separatorValue}${item.attributeValue}`).join(RUN_PARAM.separatorAttribute);
        return DeviceTypeService.updateRunStatus(this.deviceTypeId, runValue).then(res => {
            this.showMessageSuccess('保存全局运行状态成功');
        });
    }

    deleteItem(index, item, type) {
        let list = [];
        switch (type) {
            case 'faultAttribute':
                list = this.faultAttributeList;
                break;
            case 'controlAttribute':
                list = this.controlAttributeList;
                break;
            case 'runAttribute':
                list = this.runAttributeList;
                break;
        }
        if (item.attributeKey) {
            this.$confirm({
                title: '确认删除',
                content: `是否确认删除？删除后会导致此依赖属性的历史数据错误！`,
                onOk: () => {
                    list.splice(index, 1);
                }
            });
        } else {
            list.splice(index, 1);
        }
    }

    saveFaultStatus() {
        // if (this.faultAttributeList) {
        //     for (let i = 0; i < this.faultAttributeList.length; i++) {
        //         if (!this.faultAttributeList[i].attributeKey || _.isNil(this.faultAttributeList[i].attributeValue)) {
        //             this.showMessageWarning(`故障状态第${i + 1}行配置不能为空`);
        //             return;
        //         }
        //     }
        // }
        const falutValue = _.map(this.handleEmpty(this.faultAttributeList), item => `${item.attributeKey}${FAULT_PARAM.separatorValue}${item.attributeValue}`).join(FAULT_PARAM.separatorAttribute);
        return DeviceTypeService.updateFaultStatus(this.deviceTypeId, falutValue).then(res => {
            this.showMessageSuccess('保存全局故障状态成功');
        });
    }

    saveControlStatus() {
        // if (this.controlAttributeList) {
        //     for (let i = 0; i < this.controlAttributeList.length; i++) {
        //         if (!this.controlAttributeList[i].attributeKey || _.isNil(this.controlAttributeList[i].attributeValue)) {
        //             this.showMessageWarning(`可控状态第${i + 1}行配置不能为空`);
        //             return Promise.reject();
        //         }
        //     }
        // }
        const controlValue = _.map(this.handleEmpty(this.controlAttributeList), item => `${item.attributeKey}${CONTROL_PARAM.separatorValue}${item.attributeValue}`).join(CONTROL_PARAM.separatorAttribute);
        return DeviceTypeService.updateControlStatus(this.deviceTypeId, controlValue).then(res => {
            this.showMessageSuccess('保存全局可控状态成功');
        });
    }

    dialogOK(): any {
        // if (this.faultAttributeList) {
        //     for (let i = 0; i < this.faultAttributeList.length; i++) {
        //         if (!this.faultAttributeList[i].attributeKey || _.isNil(this.faultAttributeList[i].attributeValue)) {
        //             this.showMessageWarning(`故障状态第${i + 1}行配置不能为空`);
        //             return;
        //         }
        //     }
        // }
        // if (this.runAttributeList) {
        //     for (let i = 0; i < this.runAttributeList.length; i++) {
        //         if (!this.runAttributeList[i].attributeKey || _.isNil(this.runAttributeList[i].attributeValue)) {
        //             this.showMessageWarning(`运行状态第${i + 1}行配置不能为空`);
        //             return;
        //         }
        //     }
        // }
        const faultAttributeCount = _.countBy(this.faultAttributeList, item => `${item.attributeKey}|${item.attributeValue}`);
        let hasDuplicateFaultConfig = false;
        _.forOwn(faultAttributeCount, (value, key) => {
            if (key && value > 1) {
                hasDuplicateFaultConfig = true;
                this.showMessageWarning(`故障状态${key.split('|')[0]}设置重复了`);
            }
        });
        if (hasDuplicateFaultConfig) {
            return;
        }

        const runAttributeCount = _.countBy(this.runAttributeList, item => `${item.attributeKey}|${item.attributeValue}`);
        let hasDuplicateRunConfig = false;
        _.forOwn(runAttributeCount, (value, key) => {
            if (key && value > 1) {
                hasDuplicateRunConfig = true;
                this.showMessageWarning(`运行状态${key.split('|')[0]}设置重复了`);
            }
        });
        if (hasDuplicateRunConfig) {
            return;
        }

        const falutValue = _.map(this.handleEmpty(this.faultAttributeList), item => `${item.attributeKey}${FAULT_PARAM.separatorValue}${item.attributeValue}`).join(FAULT_PARAM.separatorAttribute);
        const runValue = _.map(this.handleEmpty(this.runAttributeList), item => `${item.attributeKey}${RUN_PARAM.separatorValue}${item.attributeValue}`).join(RUN_PARAM.separatorAttribute);
        Promise.all([DeviceTypeService.updateFaultStatus(this.deviceTypeId, falutValue), DeviceTypeService.updateRunStatus(this.deviceTypeId, runValue), this.saveControlStatus()]).then(res => {
            this.dialogClose();
            this.$emit('dialogOK');
        });
    }

    addAttribute() {
        this.faultAttributeList.push({ attributeKey: undefined, attributeValue: undefined, optionList: undefined });
    }
    addRunAttribute() {
        this.runAttributeList.push({ attributeKey: undefined, attributeValue: undefined, optionList: undefined });
    }
    addControlAttribute() {
        this.controlAttributeList.push({ attributeKey: undefined, attributeValue: undefined, optionList: undefined });
    }
    attributeChange(attribute: { attributeKey: string, attributeValue: string, optionList: Array<any> }, isClear: boolean = true) {
        if (isClear) {
            attribute.attributeValue = undefined;
        }
        if (!attribute.attributeKey) {
            attribute.optionList = undefined;
            return;
        }
        const attributeModel = _.find(this.allAttributes, item => item.identifier === attribute.attributeKey);
        if (!attributeModel) {
            return;
        }
        if (attributeModel.thingsModelTypeModel.thingsModelType === ThingsModelType.BOOLEAN) {
            attribute.optionList = attributeModel.thingsModelTypeModel.boolSpecs;
        } else {
            attribute.optionList = attributeModel.thingsModelTypeModel.enumList;
        }
    }

    dialogClose() {
        this.allAttributes = null;
        this.faultAttributeList = null;
        this.runAttributeList = null;
        this.controlAttributeList = null;
        this.dialogVisible = false;
    }

    handleEmpty(attributeList: Array<any>) {
        return _.filter(attributeList, item => item.attributeKey && item.attributeValue);
    }
}
