var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          staticClass: "form-edit-component-large",
          attrs: { title: "日志", visible: _vm.dialogVisible },
          on: { cancel: _vm.dialogClose },
        },
        [
          _c(
            "div",
            { staticClass: "things-sync-dialog form-edit-component-large" },
            [
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: { y: 640 },
                  "row-key": "id",
                  size: "small",
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.checkUpdate(2, record)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("edage-async-log-dialog", { ref: "edageAsyncLogDialog" }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "jtl-button",
                { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }