
































import { Component, Ref } from 'vue-property-decorator';
import { ViewModeType } from '@common-src/model/enum';
import TableComponent from '@common-src/mixins/table-component';
import { ThingsFunctionEntityModel } from '@common-src/entity-model/things-function-entity';
import DeviceTypeService2 from '@common-src/service/device-type';
import EdageAsyncLogDialog from './edage-sync-log.dialog.vue';

@Component({
    components: {
        'edage-async-log-dialog': EdageAsyncLogDialog
    }
})
export default class EdageAsyncDialog extends TableComponent<any, any> {
    dialogVisible: boolean = false;
    dialogOpen(): void {
        this.dialogVisible = true;
        this.$nextTick(() => {
            this.resetpage();
            this.initTable({
                listFunc: DeviceTypeService2.statisticSyncList,
                tableColumns: ThingsFunctionEntityModel.getEdageTypeTabelColumns()
            });
            if (this.isFirstLoad) {
                this.getList();
            }
        });
    }
    resetpage() {
        this.page = 1;
        this.pageSize = 20;
    }
    checkUpdate(type: ViewModeType, record: object) {
        (this.$refs.edageAsyncLogDialog as EdageAsyncLogDialog).dialogOpen(type, record);
    }
    dialogClose() {
        this.dialogVisible = false;
    }
}
