

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { FormControlSelectGroupModel, FormControlType } from '@common-src/model/form-control';
import { GroupEntityType } from '@common-src/model/enum';
import DeviceTypeService from '@common-src/service/device-type';
import { DeviceTypeEntityModel } from '@common-src/entity-model/device-type-entity';

@Component
export default class UpdateGroupComponent extends FormDialogComponent<any> {
    // private ids: Array<string>;
    deviceTypeModel: DeviceTypeEntityModel;
    dialogOpen(deviceTypeModel: DeviceTypeEntityModel): void {
        this.deviceTypeModel = deviceTypeModel;
        this.dialogVisible = true;
        this.dialogTitle = '修改分组';
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItem: FormControlSelectGroupModel = {
                    key: 'groupId',
                    label: '分组',
                    type: FormControlType.SELECT_GROUP,
                    groupType: GroupEntityType.DEVICE_TYPE,
                    required: true,
                    value: deviceTypeModel.groupId
                };
                this.jtlForm.initFormByControls([controlItem]);
            }
        });
    }

    dialogOK(): Promise<any> {
        if (!this.jtlForm) {
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            DeviceTypeService.updateGroup(this.deviceTypeModel.id, this.jtlForm.formModel.groupId).then(res => {
                this.showMessageSuccess('修改产品分组成功');
                this.dialogClose();
                this.$emit('dialogOK');
            });
        }).catch(err => {
            throw err;
        });
    }
}
