

















import { Component, Ref } from 'vue-property-decorator';
import DeviceDriverService from '@common-src/service3/device-driver';
import DeviceService from '@common-src/service3/device';
import DeviceTypeService from '@common-src/service/device-type';
import FormComponent from '@common-src/mixins/form-component';
import { ViewModeType } from '@common-src/model/enum';
import { FormControlType } from '@common-src/model/form-control';
import TransformComponent from '@common-src/mixins/transform-component';
import { DeviceTypeEntityModel } from '@common-src/entity-model/device-type-entity';

@Component
export default class DriverConnectDialog extends TransformComponent {
    productId: string = null;
    @Ref('remoteForm')
    jtlForm: FormComponent<any>;
    dialogOpen(deviceTypeModel: DeviceTypeEntityModel): void {
        this.dialogVisible = true;
        this.productId = deviceTypeModel.id;
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems: Array<any> = [
                    {
                        key: 'drivers',
                        label: '驱动',
                        mode: 'multiple',
                        type: FormControlType.SELECT,
                        optionsPromise: DeviceDriverService.getAllDriver,
                        required: true,
                        value: deviceTypeModel.drivers
                    }
                ];
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                const params = {
                    drivers: this.jtlForm.formModel.drivers,
                    id: this.productId
                };
                DeviceTypeService.syncDriverUpdateEdge(params).then(() => {
                    this.showMessageSuccess('编辑驱动成功！');
                    this.dialogClose();
                    this.$emit('dialogOK');
                }).catch(err => {
                    throw err;
                });
            });
        });
    }
    dialogClose() {
        this.dialogVisible = false;
    }
}
