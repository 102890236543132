














































import { Component, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TableComponent from '@common-src/mixins/table-component';
import { ThingsFunctionEntityModel } from '@common-src/entity-model/things-function-entity';
import { ViewModeType } from '@common-src/model/enum';
import DeviceTypeService2 from '@common-src/service/device-type';
import EdageAsyncDialog from './edage-sync.dialog.vue';

@Component({
    components: {
        'edage-async-dialog': EdageAsyncDialog
    }
})
export default class EdageSyncCheckDialog extends TableComponent<any, any> {
    dialogVisible: boolean = false;
    action = 'action';
    status = 'ONLINE';
    checkLoading: boolean = false;

    dialogOpen() {
        this.dialogVisible = true;
        this.selectedRowKeys = [];
        this.resetpage();
        this.$nextTick(async() => {
            this.initTable({
                listFunc: DeviceTypeService2.syncToDoList,
                tableColumns: ThingsFunctionEntityModel.getEdageTypeTabelDetailColumns(1)
            });
            if (this.isFirstLoad) {
                this.getList();
            }
        });
    }
    get isCanSync() {
        return this?.total || 0;
    }

    // 判断中台是否在线
    get isOnline() {
        return this.status === 'ONLINE';
    }

    resetpage() {
        this.page = 1;
        this.pageSize = 20;
    }
    // 查看日志
    viewLog() {
        (this.$refs.edageAsyncDialog as EdageAsyncDialog).dialogOpen();
    }
    // 检查更新
    async checkUpdateItem() {
        this.checkLoading = true;
        try {
            const res = await DeviceTypeService2.syncUpgradeCheck();
            this.resetpage();
            this.listData = _.get(res, 'items');
            this.status = _.get(res, 'checkUpdateResult') || 'ONLINE';
            this.checkLoading = false;
            this.selectedRowKeys = [];
            this.getList();
            this.$emit('update', true);
        } catch (error) {
            this.checkLoading = false;
            this.selectedRowKeys = [];
        }
    }
    // 同步
    async asyncItem(model: any) {
        const productIds = [];
        if (model) {
            productIds.push(model.productId);
        } else {
            productIds.push(...new Set(this.selectedRowKeys.map(item => {
                const position = item.indexOf('*');
                return item.substring(0, position);
            })));
        }
        if (productIds.length === 0) {
            this.showMessageWarning('请先选择产品');
            return false;
        } else {
            this.startFullScreenLoading();
            try {
                await DeviceTypeService2.syncEdge(productIds);
                this.stopFullScreenLoading();
                this.showMessageSuccess('同步产品成功');
                this.resetpage();
                this.getList();
                this.$emit('update', true);
            } catch (error) {
                this.stopFullScreenLoading();
            }
            this.listLoading = false;
            this.selectedRowKeys = [];
        }
    }
    dialogClose(): void {
        this.dialogVisible = false;
    }
}
